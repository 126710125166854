<template>
  <div class="material__details__wrapper">
    <div class="commom_title">
      <a-row type="flex" justify="space-between">
        <a-col :span="8">
          <a-row>
            <a-col :span="2">
              <div class="commom_line"></div>
            </a-col>
            <a-col :span="6">资料详情</a-col>
          </a-row>
        </a-col>
        <a-col :span="2"></a-col>
      </a-row>
    </div>
    <a-spin :spinning="loading">
      <div class="content">
        <div class="materialShop_detail">
          <a-row>
            <a-col :span="9">
              <div class="materialShop_detail_image">
                <img :src="activeImg" alt />
              </div>
              <a-row>
                <a-col :span="6" v-for="(item, index) in obj.detailsImg || []" :key="index">
                  <div class="materialShop_other_image" @click="activeImg = item">
                    <img :src="item" alt />
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="15">
              <div class="materialShop_detail_content">
                <div class="materialShop_detail_box">
                  <div class="materialShop_detail_name">
                    <div class="material_title">{{ obj.title }}</div>
                    <div class="material_collect" @click="handleCollect">
                      <div
                        class="collect_img"
                        :style="{
                          background: `url('/img/${obj.isCollect ? 'collect' : 'collect-not'}.png')  100% 100%`,
                        }"
                      ></div>
                      <div class="collect_label">{{ obj.isCollect ? '已收藏' : '添加至收藏' }}</div>
                    </div>
                  </div>
                  <div class="materialShop_detail_text">
                    <!-- <span style="color: #ff5555">¥{{ obj.price }}</span> -->
                    <!-- <span style="font-size: 14px;margin-left:40px">非会员价：¥{{ obj.price }}</span> -->
                  </div>
                  <div class="materialShop_detail_text" style="margin-top: 40px">
                    <a-row>
                      <a-col :span="8">资料类型：{{ obj.fileType }}</a-col>
                    </a-row>
                  </div>
                  <div class="materialShop_detail_text">
                    <a-row>
                      <a-col :span="8">资料大小：{{ obj.fileSize }}</a-col>
                    </a-row>
                  </div>
                  <div class="materialShop_detail_text">
                    <a-row class="synopsis">
                      <a-col :span="24">
                        <span>资料简介：</span>
                        <div class="synopsisText">{{ obj.synopsis }}</div>
                      </a-col>
                    </a-row>
                  </div>
                </div>
                <div class="materialShop_detail_btn">
                  <!-- <a-button type="primary" @click="submit">立即购买</a-button> -->
                  <a-button type="primary" @click="previePdf" v-if="obj.fileType === 'Pdf'">预览</a-button>
                  <a-button type="primary" @click="handleDownLoad(obj)">立即下载</a-button>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>

      <div class="materialShop_detail_msg">
        <a-row :gutter="30">
          <a-col>
            <div class="materialShop_shop_detail">
              <div class="materialShop_shop_detailTit">
                <a-row>
                  <a-col :span="4">
                    <div :class="{ actived: actived }" @click="actived = true">商品详情</div>
                  </a-col>
                  <a-col :span="4">
                    <div :class="{ actived: !actived }" @click="actived = false">商品评价</div>
                  </a-col>
                </a-row>
              </div>
              <div v-if="actived">
                <div class="materialShop_shop_text" v-html="obj.content"></div>
              </div>
              <div v-if="!actived" class="content-box">
                <a-row
                  :gutter="20"
                  v-for="(item, index) in evaluationList"
                  :key="index"
                  style="border-bottom: 1px dashed black"
                >
                  <a-col :span="2">
                    <div class="portrait">
                      <img :src="item.image" />
                    </div>
                  </a-col>
                  <a-col :span="18">
                    <div class="materialShop_shop_name" style="margin-bottom: 5px">{{ item.title }}</div>
                    <div v-if="item.tab">
                      <span
                        style="font-size: 16px; padding: 0 10px; margin-right: 10px"
                        class="text"
                        v-for="(item1, index1) in item.tab.split(',')"
                        :key="index1"
                      >{{ item1 }}</span
                      >
                    </div>
                    <div class="materialShop_shop_text" style="font-size: 16px">{{ item.content }}</div>
                    <div style="font-size: 16px">{{ formatTime(item.createdTime) }}</div>
                  </a-col>
                  <a-col :span="4">
                    <div class="materialShop_shop_rate">
                      <a-rate :value="Number(item.score || 0)" disabled :tooltips="desc" />
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-spin>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { employmentLearnUserDetail, getEvaluationList, userCollect } from '@/api/msg'
import moment from 'moment'

export default {
  data () {
    return {
      desc: ['非常不满意', '不满意', '一般', '满意', '非常满意'],
      obj: {},
      actived: true,
      val: 1,
      loading: false,
      evaluationList: [],
      activeImg: '',
      previewVisible: false,
      previewImage: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    }
  },
  methods: {
    formatTime (t) {
      return t ? moment(t).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    init () {
      this.employmentLearnUserDetail()
    },
    employmentLearnUserDetail () {
      const id = this.$route.query.id
      this.loading = true
      employmentLearnUserDetail({ id })
        .then((res) => {
          this.obj = res.data
          this.activeImg = res.data.detailsImg[0]
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCollect () {
      if (this.handleIsLogin()) {
        const { id: materialId } = this.$route.query
        userCollect(materialId).then(() => {
          this.$message.success(`资料${this.obj.isCollect ? '取消收藏' : '收藏'}成功`)
          this.employmentLearnUserDetail()
        })
      }
    },
    handleCancel () {
      this.previewVisible = false
    },
    previePdf () {
      if (this.handleIsLogin()) {
        window.open(this.obj.fileName)
      }
    },
    handleDownLoad ({ fileName, title, fileType }) {
      if (this.handleIsLogin()) {
        this.$spin.show()
        try {
          var blobType
          switch (fileType) {
            case 'Doc':
              blobType = 'application/msword'
              break
            case 'Xls':
              blobType = 'application/vnd.ms-excel'
              break
            case 'Pdf':
              blobType = 'application/pdf'
              break
            case 'Docx':
              blobType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              break
            case 'Xlsx':
              blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              break
            default:
              window.open(fileName)
          }
          if (blobType) {
            this.axios({
              method: 'get',
              url: fileName,
              responseType: 'blob'
            })
              .then((res) => {
                const blob = new Blob([res], {
                  type: blobType
                })
                const a = document.createElement('a')
                const URL = window.URL || window.webkitURL
                const href = URL.createObjectURL(blob)
                a.href = href
                const type = `.${fileType.toLowerCase()}`
                a.download = title.replace(type, '') + type
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                window.URL.revokeObjectURL(href)
              })
              .finally(() => {
                this.$spin.hide()
              })
          } else {
            this.$spin.hide()
          }
        } catch (e) {
          this.$spin.hide()
        }
      }
    },
    submit () {
      if (this.handleIsLogin()) {
        this.$router.push({
          path: '/material/supplies-order',
          query: {
            id: this.$route.query.id
          }
        })
      }
    }
  },
  mounted () {
    const id = this.$route.query.id
    getEvaluationList({ id, pageIndex: 1, pageSize: 10000 }).then((res) => {
      this.evaluationList = res.data
    })
    this.init()
  }
}
</script>
<style lang="less">
.synopsis {
  position: relative;
}
.synopsisText {
  position: absolute;
  top: 0;
  display: inline-block;
}
.material__details__wrapper {
  .actived {
    color: rgb(22, 119, 255);
  }
  .materialShop_detail_box {
    height: 350px;
    border-bottom: 1px dashed #a0a0a0;
  }
  .materialShop_detail_btn {
    margin-top: 30px;
    text-align: right;
  }
  .materialShop_detail_name {
    display: flex;
    align-items: center;
    .material_collect {
      margin-left: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333333;
      opacity: 0.8;
      cursor: pointer;
      .collect_img {
        height: 22px;
        width: 22px;
        margin-right: 10px;
      }
    }
  }
}
.portrait {
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
}
.text {
  height: 36px;
  background: rgba(22, 119, 255, 0.22);
  border-radius: 14px;
}
@import url('../../common/style');
@import url('../materialShop/search/index');
</style>
